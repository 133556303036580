import * as React from "react"
import { Link, graphql } from "gatsby"

import LayoutContact from "../components/layoutContact"
import Seo from "../components/seo"

const Home = ({ data, location }) => {
  const siteTitle = "Suman Shree Neupane"

  return (
    <LayoutContact location="contact" title={siteTitle}>
      <div id="contact_form"
        class="max-w-screen-xl md:py-10 md:my-20 md:rounded-lg md:drop-shadow-md md:hover:drop-shadow-2xl px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-[#E7F6F2] text-gray-900shadow-lg">
        <div class="flex flex-col justify-between">
          <div class="flex-col">
            <h2 class="text-3xl lg:text-4xl font-bold leading-tight">Want to talk biodiversity?</h2>
            <div class="text-gray-700 mt-3">
              Let's get in touch!
            </div>
            <a href="https://goo.gl/maps/Bxqgodhy2aN6VZBz6" target="_blank" class="hover:fill-[#00acee] hover:scale-110 transition duration-300 flex items-center py-3 md:py-8">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 297 297"
                class="w-10 hover:fill-[#00acee] hover:scale-125 transition duration-300"
              >
                <path d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645
		c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645
		C259.253,49.703,209.57,0,148.5,0z M148.5,272.689c-22.049-21.366-90.243-93.029-90.243-161.892
		c0-49.784,40.483-90.287,90.243-90.287s90.243,40.503,90.243,90.287C238.743,179.659,170.549,251.322,148.5,272.689z" />
                <path d="M148.5,59.183c-28.273,0-51.274,23.154-51.274,51.614c0,28.461,23.001,51.614,51.274,51.614
		c28.273,0,51.274-23.153,51.274-51.614C199.774,82.337,176.773,59.183,148.5,59.183z M148.5,141.901
		c-16.964,0-30.765-13.953-30.765-31.104c0-17.15,13.801-31.104,30.765-31.104c16.964,0,30.765,13.953,30.765,31.104
		C179.265,127.948,165.464,141.901,148.5,141.901z" />
              </svg>
              <p class="text-dark font-semibold pl-4">Gatthaghar, Bhaktapur</p>
            </a>
            <a href="tel:+977-9849575176" class="hover:fill-[#00acee] hover:scale-110 transition duration-300 flex items-center py-3 md:py-8">
              <svg class="w-10 hover:fill-[#00acee] hover:scale-125 transition duration-300" version="1.1"
                id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 202.592 202.592"
              >
                <path d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z" />
              </svg>

              <p class="text-dark font-semibold pl-4">+977-9849575176</p>
            </a>
            <a href="mailto:sumanneupane@gmail.com" class="hover:fill-[#00acee] hover:scale-110 transition duration-300 flex items-center py-3 md:py-8">
              <svg class="w-10 h-10 hover:fill-[#00acee] hover:scale-125 transition duration-300" version="1.1"
                id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 75.294 75.294"
              >
                <path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9
		c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089
		H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065
		c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016
		c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102
		c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069
		c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z" />
              </svg>
              <p class="text-dark font-semibold pl-4">sumanshreeneupane@gmail.com</p>
            </a>
          </div>
        </div>
        <form action="https://formsubmit.co/sumanshreeneupane@gmail.com" name="contact" method="POST">
          {/* <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field"> */}
          <input
            class="w-full bg-gray-300 text-gray-900 mt-2 p-3 focus:drop-shadow-md rounded-lg focus:outline-none focus:shadow-outline"
            type="text" name="fullname" placeholder="Your Name" />
          <input
            class="mt-8 w-full bg-gray-300 text-gray-900 mt-2 p-3 focus:drop-shadow-md rounded-lg focus:outline-none focus:shadow-outline"
            type="text" name="email" placeholder="email@example.com" />
          <textarea
            class="mt-8 w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 focus:drop-shadow-md rounded-lg focus:outline-none focus:shadow-outline"
            name="message" placeholder="Your message"></textarea>
          <button type="submit"
            class="mt-8 hover:scale-105 hover:drop-shadow-xl transition duration-300 drop-shadow-lg uppercase text-sm font-bold active:drop-shadow-md tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
            Send Message
          </button>
        </form>
      </div>
    </LayoutContact>
  )
}

export default Home

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="All posts" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
