import PropTypes from "prop-types"
import React from "react"

function Footer() {

    return (
        <div class="text-center text-black font-sans bg-[#E7F6F2] pt-4">
            <div class="container max-w-full">
                <div class="flex flex-col justify-center">
                    <div id="icons" class="flex justify-center">
                        <a href="https://www.facebook.com/suman.s.neupane" target="_blank" class="mr-9 text-gray-800">
                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                                class="svg-inline--fa fa-facebook-f w-2.5 scale-125 hover:scale-150 transition duration-300 hover:fill-[#4267B2]" role="img" xmlns="http://www.w3.org/2000/svg"

                                viewBox="0 0 320 512">
                                <path
                                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                </path>
                            </svg>
                        </a>
                        <a href="https://twitter.com/sumanshreeneup1" target="_blank" class="mr-9 text-gray-800">
                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                                class="svg-inline--fa fa-twitter w-4 scale-125 hover:scale-150 transition duration-300 hover:fill-[#00acee]" role="img" xmlns="http://www.w3.org/2000/svg"

                                viewBox="0 0 512 512">
                                <path
                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                                </path>
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/suman.s.neupane/" target="_blank" class="mr-9 text-gray-800">
                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
                                class="svg-inline--fa fa-instagram scale-125 hover:scale-150 transition duration-300 w-3.5 hover:fill-[#8a3ab9]" role="img" xmlns="http://www.w3.org/2000/svg"

                                viewBox="0 0 448 512">
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                </path>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/in/suman-shree-neupane-490623145/" target="_blank" class="mr-9 text-gray-800">
                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
                                class="svg-inline--fa fa-linkedin-in scale-125 hover:scale-150 transition duration-300 w-3.5 hover:fill-[#0072b1]" role="img" xmlns="http://www.w3.org/2000/svg"

                                viewBox="0 0 448 512">
                                <path
                                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                </path>
                            </svg>
                        </a>
                        {/* <a href="#!" target="_blank" class="text-gray-800">
                            <svg class="hover:fill-[#FF0000] scale-125 hover:scale-150 transition duration-300" width="25" height="17" viewBox="0 0 25 17" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.5006 2.51196C23.3647 2.02719 23.1 1.5883 22.7346 1.24196C22.3589 0.884962 21.8984 0.629595 21.3966 0.499959C19.5186 0.00495878 11.9946 0.00495889 11.9946 0.00495889C8.85795 -0.0307285 5.72205 0.126233 2.60462 0.474959C2.10281 0.614172 1.64318 0.875246 1.26662 1.23496C0.896617 1.59096 0.628617 2.02996 0.488617 2.51096C0.15231 4.3227 -0.0110908 6.1623 0.000617183 8.00496C-0.0113828 9.84596 0.151617 11.685 0.488617 13.499C0.625617 13.978 0.892617 14.415 1.26362 14.768C1.63462 15.121 2.09662 15.376 2.60462 15.511C4.50762 16.005 11.9946 16.005 11.9946 16.005C15.1353 16.0407 18.2752 15.8837 21.3966 15.535C21.8984 15.4053 22.3589 15.15 22.7346 14.793C23.0999 14.4467 23.3643 14.0077 23.4996 13.523C23.8447 11.7119 24.0125 9.87155 24.0006 8.02796C24.0266 6.17656 23.859 4.32852 23.5006 2.51196ZM9.60262 11.429V4.58196L15.8626 8.00596L9.60262 11.429Z" />
                            </svg>
                        </a> */}
                    </div>
                    <div id="email" class="flex font-semibold justify-center pt-5 pb-2">
                        <a href="mailto:sumanshreeneupane@gmail.com" class="transition duration-300 flex font-semibold hover:text-blue-500">sumanshreeneupane@gmail.com</a>
                    </div>
                    <div id="copyright" class="flex justify-center items-center p-2">
                        <p>&copy; Suman Shree Neupane</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: `Suman Shree Neupane`,
}

export default Footer
